.stat-box {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .stat-box h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .stat-box p {
    margin: 8px 0 0;
    font-size: 24px;
    font-weight: bold;
  }
  
  .stat-boxes-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
