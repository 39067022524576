.hero-container {
    position: relative;
    height: 100vh;
    background-image: url('../assets/medigap-dalle-office.webp');
    background-size: cover;
    border-radius: 5px;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    text-align: center;
    z-index: 1;
}

.carousel-container {
    width: 80%;
    margin-top: 25vh;
    opacity: 0.9;
}

.carousel-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousel-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.carousel-caption {
    padding: 10px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
    width: 100%;
}
