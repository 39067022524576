.status-icon {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
}

.green {
    background-color: green;
}

.red {
    background-color: red;
}
