.dashboard-container {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center; /* Center the container */
}

.section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center items if fewer than 3 */
  width: 100%;
}

.section .stat-box,
.section .chart {
  flex: 1 1 calc(33.33% - 20px); /* Adjust to fit three items per row */
  min-width: 300px;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
